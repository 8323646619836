import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as streamingActions } from '../../modules/streaming'
import Footer from '../../components/Footer';
import LayoutProvider from '../../components/layout/LayoutProvider';
import { StoreState } from '../../modules';
import Title from '../../components/title/Title';
import { Messages } from '../../utils';
import { Link } from 'gatsby';

interface Props {
  location: any
}

interface State {
}

class DemoContainer extends React.Component<Props, State> {
  state: State = {
  }

  public render() {
    return (
      <LayoutProvider location={this.props.location} className="demo-container" render={() => 
        <>
          <div className="content-container">
            <div className="demo-content row">
              <Title size="xlarge" color="black" className="montserrat-font" style={{margin: "0"}}>DEMO</Title>
              <div className="flex-center">
                {Messages.DEMO_CONTETS.map(content => (
                  <Link to={content.link} className="card demo-group" >
                    <div className="demo-content-group">
                      <Title size="large" color="black" className="montserrat-font">{content.title}</Title>
                      <p>{content.desc}</p>
                    </div>
                    <img src={content.Img} alt={`${content.title} image`} width="287" height="160"/>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </>
      } />
    )
  }
}

export default connect(
	({ streaming } : StoreState) => ({
    goodsList: streaming.goodsList,
    title: streaming.title,
    video: streaming.video,
    streamState: streaming.streamState,
	}),
	(dispatch) => ({
    actions: bindActionCreators(streamingActions, dispatch),
  })
)(DemoContainer)